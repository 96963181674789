import { GetServerSideProps } from "next"
import Head from "next/head"
import { ReactElement } from "react"
import {
  Grid,
  GridItem,
  H1,
  ImageGallery,
  LinkCards,
  Markdown,
  Message,
  NewsItems,
  P,
  PageWidthContainer,
  ParentLink,
  PublicLayout,
  StyledLinkCardsComponentContainer,
  StyledPageContent,
  StyledPageContentItemContainer,
  StyledPageHeader,
  StyledPageLead,
  TopScrollButton,
  VideoPlayer,
} from "src/components"
import appConfig from "src/config"
import { IFooterData, INewsItem, IPage } from "src/services"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import getAbsoluteSrc from "src/utils/get-absolute-src"
import { setCacheControl } from "src/utils/set-cache-control"
import { NotFoundException, SSRException } from "src/utils/ssr-exception"

import { NewsItemsWrapper } from "."

interface Props {
  data: IPage
  footerData: IFooterData
  newsItems: INewsItem[] | null
}

export const PageHeader = styled("header", {
  padding: "$80 0 0",

  "@mdDown": {
    padding: "$40 0 0",
  },

  H1: {
    lineHeight: "1.15",
    marginBottom: "$10",
  },

  P: {
    maxWidth: "825px",
    fontFamily: "$nova",
    fontWeight: "$medium",
    color: "$tertiary",
  },

  variants: {
    hasParentLink: {
      true: {
        padding: "$40 0 0",

        "@mdDown": {
          padding: "$20 0 0",
        },
      },
    },
  },
})

export const PageContent = styled("div", {
  paddingBottom: "$40",
  backgroundColor: "$white",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginBottom: "$40",
    marginLeft: "-$15",
  },

  "@mdUp": {
    marginBottom: "$80",
    marginLeft: "0",
  },

  variants: {
    noPadding: {
      true: {
        paddingBottom: "0",
      },
    },
  },
})

export const PageLead = styled("div", {
  p: {
    margin: 0,
  },

  "@mdDown": {
    padding: "$20 $15",
  },

  "@mdUp": {
    padding: "$40 0",
  },
})

export const PageContentItemContainer = styled("div", {
  padding: "$40 0",

  "@mdDown": {
    padding: "$20 $15",
  },
})

export const LinkCardsComponentContainer = styled("div", {
  marginBottom: "$15",

  "@mdUp": {
    marginBottom: "$80",
  },
})

export default function Index({
  data,
  footerData,
  newsItems,
}: Props): ReactElement {
  return (
    <PublicLayout footerData={footerData}>
      <Head>
        <title>{data.title}</title>
        <meta name="title" content={data.seo.title} />
        <meta name="description" content={data.seo.description} />
        {data.seo.noIndex ? <meta name="robots" content="noindex" /> : null}
        {data.seo.ogImage ? (
          <meta
            property="og:image"
            content={getAbsoluteSrc(data.seo.ogImage.url)}
          />
        ) : null}
      </Head>

      <StyledPageHeader hasParentLink={!!data.parentLink}>
        <PageWidthContainer>
          {data.parentLink ? <ParentLink {...data.parentLink} /> : null}
          <H1 data-testid="page-title">{data.title}</H1>
          <P size="extraLarge" data-testid="page-subtitle">
            {data.subtitle}
          </P>
        </PageWidthContainer>
      </StyledPageHeader>

      {(!!data.lead || data.content.length !== 0) && (
        <PageWidthContainer>
          <Grid>
            <GridItem col={[2, 10]} medium={[1, 12]}>
              <StyledPageContent data-cy="page-content">
                <Grid columns={10}>
                  {data.lead && (
                    <GridItem col={[2, 7]} small={[1, 10]}>
                      <StyledPageLead>
                        <P size="large" bold data-cy="page-lead">
                          {data.lead}
                        </P>
                      </StyledPageLead>
                    </GridItem>
                  )}

                  {data.content.map((contentItem) => {
                    const key = `${contentItem.__component}_${contentItem.id}`

                    switch (contentItem.__component) {
                      case "page.markdown":
                        return (
                          <GridItem col={[3, 6]} small={[1, 10]} key={key}>
                            <StyledPageContentItemContainer>
                              <Markdown>{contentItem.markdown}</Markdown>
                            </StyledPageContentItemContainer>
                          </GridItem>
                        )
                      case "page.video":
                        return (
                          <GridItem col={[2, 8]} small={[1, 10]} key={key}>
                            <StyledPageContentItemContainer>
                              <VideoPlayer {...contentItem} />
                            </StyledPageContentItemContainer>
                          </GridItem>
                        )
                      case "page.images":
                        return (
                          <GridItem col={[2, 8]} small={[1, 10]} key={key}>
                            <StyledPageContentItemContainer>
                              <ImageGallery images={contentItem.images} />
                            </StyledPageContentItemContainer>
                          </GridItem>
                        )
                      default:
                        return null
                    }
                  })}
                </Grid>
              </StyledPageContent>
            </GridItem>
          </Grid>
        </PageWidthContainer>
      )}
      {!!data.linkCards && (
        <StyledLinkCardsComponentContainer>
          <PageWidthContainer>
            <LinkCards linkCards={data.linkCards} />
          </PageWidthContainer>
        </StyledLinkCardsComponentContainer>
      )}
      {newsItems && (
        <>
          <NewsItemsWrapper data-cy="news-items-container">
            <NewsItems
              newsItems={newsItems}
              title={<Message id="sustainability.page.news.title" />}
              subtitle={<Message id="program.page.news.subtitle" />}
              linkText={<Message id="program.page.news.button" />}
              linkHref={`/nieuws/tag/${appConfig.PROGRAM_BOARD_NEWS_TAG_ID}`}
            />
          </NewsItemsWrapper>
        </>
      )}
      <TopScrollButton />
    </PublicLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  res,
  query,
}) => {
  setCacheControl(res)
  let route: string = "/"

  if (Array.isArray(query.path) && query.path.length !== 0) {
    route = `/${query.path.join("/")}`
  } else if (typeof query.path === "string") {
    route = `/${query.path}`
  }

  const shouldGetNews = route === "/duurzaamheid" || false

  try {
    const pages = await api.strapi.getPagesByRoute(route)
    const newsItems =
      (shouldGetNews
        ? await api.strapi.getNewsItems(0, 2, appConfig.DURABILITY_NEWS_TAG_ID)
        : null) || null

    if (!pages.length) {
      throw NotFoundException()
    }

    return {
      props: {
        data: pages[0],
        newsItems,
      },
    }
  } catch (err) {
    return await SSRException(err, res)
  }
}
